<template>
	<div class="policy_wrap">
		<h2 class="policy">오디오북 청소년 보호정책</h2>
		<div class="text_bundle_wrap">
			<div class="text_bundle">
				<p>
					오디오북는 청소년의 안전한 인터넷 사용을 돕기 위해 정보통신망 이용촉진 및 정보보호 등에 관한
					법률에서 정한 청소년 보호정책을 시행하고 있습니다.
				</p>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3>1. 청소년 보호를 위한 목표 및 기본 원칙</h3>
			<div class="text_bundle">
				<p>
					청소년이 아무런 제한장치 없이 유해정보에 노출되지 않도록 청소년유해매체물에 대해서는 인증장치를 마련
					· 적용하고 있으며, 성인 인증장치가 마련된 서비스에 대해서는 이용범위를 제한하게 하는 등 관리를
					진행하고 있으며 불법적이거나 청소년에 유해한 키워드에 대한 내용 및 콘텐츠를 지속적으로 관리하고
					있습니다.
				</p>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3>2. 청소년 보호 장치</h3>
			<div class="text_bundle">
				<p>
					청소년이 아무런 제한장치 없이 유해정보에 노출되지 않도록 청소년유해매체물에 대해서는 인증장치를 마련
					· 적용하고 있으며, 성인 인증장치가 마련된 서비스에 대해서는 이용범위를 제한하게 하는 등 관리를
					진행하고 있으며 불법적이거나 청소년에 유해한 키워드에 대한 내용 및 콘텐츠를 지속적으로 관리하고
					있습니다.
				</p>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3>3. 유해정보로 인한 피해상담 및 고충처리</h3>
			<div class="text_bundle">
				<p>
					유해정보로 인한 피해상담 및 고충처리를 위한 전문인력을 배치하여 구제조치의 지연 및 처리 미숙으로
					인한 피해가 확산되지 않도록 노력하고 있으며 자체적으로 처리가 곤란할 경우 피해관련기관을 안내해
					드리고 있습니다.
				</p>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3>4. 유해정보에 대한 청소년접근제한 및 관리조치</h3>
			<div class="text_bundle">
				<p>
					회사는 청소년이 아무런 제한장치 없이 청소년 유해정보에 노출되지 않도록 별도의 인증장치를 마련,
					적용하며 청소년 유해정보가 노출되지 않기 위한 예방차원의 조치를 강구합니다.
				</p>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<h3>5. 유해정보로부터 청소년을 보호하기 위한 교육</h3>
			<div class="text_bundle">
				<p>
					청소년보호담당자 및 각 서비스 담당자들을 대상으로 청소년 보호를 위한 각종 관련 법령 및 제재기준,
					유해정보 발견 시, 대처방법, 위반사항 처리에 대한 보고절차 등을 교육하고 있습니다.
				</p>
			</div>
		</div>
		<div class="text_bundle_wrap">
			<div class="text_bundle">
				<p class="p_point">[청소년 보호책임자]</p>
				<ul class="scd">
					<li>이름 : 박은별</li>
					<li>소속 : 플랫폼운영지원실</li>
					<li>직위 : 과장</li>
					<li>전화 : 070-8825-5004</li>
					<li>문의 : info@ideaconcert.com</li>
				</ul>
			</div>
		</div>
	</div>
</template>
